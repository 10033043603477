import { POST } from "../../helpers";
import { useLocation, useParams } from "react-router-dom";
interface IndexProps {
    data:any;
    survey_id:number;
}
 
const Index = async({data,survey_id}:IndexProps) => {
    // const paramsValue = useParams();
    return await POST(`/form/${survey_id}`, data).then(([statusCode, res])=>{
        console.log(statusCode, res);
        return {statusCode, res}
    })
}
 
export default Index;
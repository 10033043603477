import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
import Formpage from "../surveyjs/index";
import SurveyHome from "../pages/SurveyHome"


export default function Router() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/:survey_title/:survey_id">
<Route index element={<Main />} />
          <Route path="Survey-Home/:id" element={<SurveyHome />} />
          </Route>
          {/* <Route path="/Diabetes-Survey" element={<Formpage />} /> */}
          <Route path="/Survey-page/:survey_id" element={<Formpage />} />
          {/* <Route path="/Survey-page" element={<Formpage />} /> */}

          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

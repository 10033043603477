import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import "./index.css";
// import { json } from "./survey";
import { json } from "./SurveyC";
import { ResponseContext } from "../context/ResponseContext";
import { postSurvey } from "../api";
import { useLocation, useParams } from "react-router-dom";
import {GET} from "../helpers";

StylesManager.applyTheme("defaultV2");
function SurveyComponent() {
  const[jsons, setJsons]=useState({});

  const paramsValue = useParams();
  // console.log("params value",paramsValue.survey_id)
  const survey_id=Number(paramsValue.survey_id)
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  useEffect(() => {
    // console.log("EMial",email)
  }, [])
  
  const { values, setValues } = useContext(ResponseContext);
  
  const surveyComplete = useCallback(async (sender: any) => {
  
    await postSurvey({ data:{responses:sender.data,email},survey_id }).then((e) => {
      console.log(e)
      if (e?.statusCode === 404) {
        // alert('You have already completed the survey')
      }
      // alert(e?.statusCode)
    })
    // console.log("total value",sender.data);
  }, []);
  // console.log(values)

  const surveyCss = {
    navigation: {
      complete: "sd-btn--action sd-navigation__complete-btn custom_btn",
      prev: "sd-navigation__prev-btn custom_btn",
      next: "sd-navigation__next-btn custom_btn",
      start: "sd-navigation__start-btn custom_btn",
      preview: "sd-navigation__preview-btn custom_btn"
    },
    radiogroup: {
      item: "sd-item sd-radio sd-selectbase__item custom_radio_item",
      itemChecked: "sd-item--checked sd-radio--checked custom_radio_item_checked",
      itemHover: "sd-item--allowhover sd-radio--allowhover custom_radio_hover",
      itemDecorator: "sd-item__svg sd-radio__svg",
      materialDecorator:
        "sd-item__decorator sd-radio__decorator custom_radio_decorator",
      label: "sd-selectbase__label custom_radio_label"
    }
    
  };

  // ------------------------Get Question Method-------------------------------
  useEffect(() => {
    console.log("Function Triggered")
 
    const fetchSurveyData = async () => {
      
      try {
        const [status, response] = await GET(`/questiondata/${paramsValue.survey_id}`);
        console.log("Response from API",status,response)
        if (status === 200) {
       
         
       
          setJsons(response[0][0]);


        } else {
          console.error("Failed to fetch survey data");
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
        
      }
    };
    fetchSurveyData();
  }, [paramsValue.survey_id]);




  const survey = new Model(jsons);
  // const survey = json ? new Model(json) : null;
  survey.onComplete.add(surveyComplete);
 
  return (
    <>
      <div className="welcome-name">Welcome {email}</div>
      {/* {survey && <Survey model={survey} />} */}
      <Survey model={survey} />
      
    </>
  );
}
export default SurveyComponent;

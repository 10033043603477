import React from "react";

const twitter = () => {
  return (
    <>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#FAFAFA"/>
<path d="M7.04077 8L13.605 16.2743L7 23H8.48646L14.2692 17.1102L18.9409 23H24L17.0689 14.2613L23.2168 8H21.7304L16.4046 13.4238L12.0998 8H7.04077ZM9.22714 9.03288H11.5511L21.8136 21.9687H19.4897L9.22714 9.03288Z" fill="#407BFF"/>
</svg>

   
    </>
  );
};

export default twitter;

import React from "react";

const linkdln = () => {
  return (
    <>
   <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#FAFAFA"/>
<path d="M16.0923 13.1714C16.2955 12.9433 16.4628 12.7117 16.6711 12.5151C17.3122 11.9109 18.0709 11.6058 18.9563 11.6134C19.4429 11.6175 19.9239 11.6524 20.3932 11.7883C21.4661 12.0984 22.09 12.8374 22.3875 13.8855C22.6105 14.6716 22.6516 15.479 22.6531 16.2884C22.6561 17.9963 22.648 19.7043 22.6521 21.4123C22.6521 21.5725 22.6075 21.614 22.4504 21.613C21.5705 21.6059 20.6907 21.6054 19.8108 21.613C19.6558 21.6146 19.6228 21.5664 19.6228 21.4199C19.6279 19.7946 19.6289 18.1697 19.6238 16.5443C19.6223 16.1368 19.5965 15.7298 19.4824 15.334C19.2721 14.6047 18.7496 14.2332 17.9858 14.2732C16.9422 14.3285 16.3999 14.8449 16.2666 15.9062C16.2347 16.1596 16.22 16.413 16.22 16.6685C16.2215 18.2493 16.2185 19.8295 16.2241 21.4103C16.2246 21.5679 16.185 21.6146 16.0244 21.613C15.1379 21.6054 14.252 21.6059 13.3656 21.6125C13.2237 21.6135 13.1826 21.575 13.1826 21.4316C13.1867 18.3025 13.1867 15.1733 13.1826 12.0437C13.1826 11.8891 13.2333 11.8531 13.3798 11.8542C14.2216 11.8602 15.0629 11.8613 15.9048 11.8542C16.0598 11.8526 16.0999 11.9028 16.0963 12.0498C16.0877 12.4238 16.0933 12.7979 16.0933 13.1719L16.0923 13.1714Z" fill="#407BFF"/>
<path d="M11.2708 16.7479C11.2708 18.2958 11.2683 19.8441 11.2744 21.3919C11.2749 21.5643 11.2323 21.616 11.0544 21.6139C10.1751 21.6043 9.29523 21.6063 8.41589 21.6124C8.27499 21.6134 8.23242 21.578 8.23242 21.4325C8.23648 18.2983 8.23648 15.1636 8.23344 12.0294C8.23344 11.8997 8.26486 11.8535 8.40271 11.8546C9.29523 11.8601 10.1872 11.8622 11.0798 11.8535C11.2511 11.852 11.2744 11.9184 11.2739 12.0654C11.2698 13.6264 11.2713 15.1869 11.2713 16.7479H11.2708Z" fill="#407BFF"/>
<path d="M11.5138 8.74803C11.5148 9.72012 10.7257 10.5118 9.75615 10.5123C8.79876 10.5123 8.00305 9.72012 8.00001 8.76324C7.99697 7.79571 8.79167 6.99899 9.7597 7C10.7206 7.00101 11.5128 7.79014 11.5138 8.74803Z" fill="#407BFF"/>
</svg>

   
    </>
  );
};

export default linkdln;

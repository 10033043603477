import React from 'react'
import Logo from "../../assets/rivaziaAnim";
import "./style.css";
export default function index() {
  return (
    <>
    <nav className="nav-survey">
          <div className="logo-container">
            <Logo />
          </div>
          <div className='rivazia-link' style={{paddingLeft:'3%',paddingBottom:'5px'}}>

        <a href="https://dev.rivazia.com/" style={{color:'blue'}}>https://dev.rivazia.com</a>
          </div>

        </nav>
    </>
  )
}

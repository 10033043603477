import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { useNavigate,useParams } from "react-router-dom";
import Logo from "../../assets/rivaziaAnim";
// import statisticsimg from "../../assets/Statistics-bro.png"
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import BannerImg from "../../assets/bannerImg";
import Facebook from "../../assets/facebook";
import Instagram from "../../assets/instagram";
import { ResponseContext } from "../../context/ResponseContext";
import { post } from "../../Utils/Functions/index";
import { apiEndPoint } from "../../Utils/Functions/Constants";
import { POST ,GET} from "../../helpers";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import StateCity from "../../components/SateCity";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export default function Index() {
  const paramsValue = useParams();
  
  const {surveyData,setSurveyData}=useContext(ResponseContext);
  const navigate = useNavigate();
  // console.log("surveyData inside surve-home page",surveyData)
  // const InitialValues = {
  //   name: "",
  //   number: "",
  //   mail: "",
  // };
  // const [values, setValues] = useState(InitialValues);
  // const schema=z.object({
  //   email
  // })
  const REQUEST_PARAMS = {
    method: "GET",
    headers: {
      "X-CSCAPI-KEY":
        "a0E2YkJ1WUlZWkN6M3RyNGFLS0prZVRUNTlreUhEN1lvZEtYZjlqbQ==",
    },
  };
  const [states, setStates] = useState<Array<any>>();
  const [state, setState] = useState<string>();
  const [cities, setCities] = useState<Array<any>>();

  const phoneRegex = /^\d{10}$/;
  const { values, setValues } = useContext(ResponseContext);
  // console.log("ResponseContext", values);
  const schema = z.object({
    city: z.string().optional(),
    email: z.string().email(),
    first_name: z
      .string()
      .min(3, { message: "First Name must contain minimum 3 characters" })
      .max(20, { message: "First Name can maximum contain 20 characters" }),
    last_name: z
      .string()
      .min(1, { message: "First Name must contain minimum 1 characters" })
      .max(20, { message: "First Name can maximum contain 20 characters" })
      .optional(),
    phone: z.string(),
    state: z.string().optional()
    // survey_id: z.string().default("DS_06_09_23"),
    // survey_title: z.string().default("Employee survey within Organization"),
  });
 
  type SurveyFormSubmitType = {
    city?: string;
    email: string;
    first_name: string;
    last_name?: string;
    phone: string;
    state?: string;
    // survey_id: string;
    // survey_title: string;
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<SurveyFormSubmitType>({ resolver: zodResolver(schema) });
  const [disable, setDisable] = useState(true);
 


  const onValueChange = (key: any) => (value: any) => {
    setValues({ ...values, [key]: value });
  };

  useEffect(() => {
    if (
      (values?.first_name.length < 5,
      values?.last_name.length < 1,
      values?.state.length < 0,
      values?.phone_number.length < 10,
      values?.email.length < 10)
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    values?.first_name,
    values?.last_name,
    values?.state,
    values?.phone_number,
    values?.email,
  ]);
  const fetchStates = async () => {
    try {
      const responseJSON = await fetch(
        "https://api.countrystatecity.in/v1/countries/IN/states",
        REQUEST_PARAMS
      );
      const response = await responseJSON.json();
      console.log("country",response);
      setStates(response);
    } catch (error) {
      console.log(error);
    }
  };



  const fetchCities = async () => {
    try {
      console.log("STATE", state);
      const stateCode = state.split("-")[1];
      console.log(stateCode);

      const responseJSON = await fetch(
        `https://api.countrystatecity.in/v1/countries/IN/states/${stateCode}/cities`,
        REQUEST_PARAMS
      );
      const response = await responseJSON.json();
      console.log("CITY response", response);
      setCities(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStates();
  }, []);
  useEffect(() => {
    console.log("FETCH TRIGGERED");
    fetchCities();
  }, [state]);
  const  [user,setUser] = useState<any>();
//   const fetchExistingUser =async (data:any) => {
//     console.log("function 1",data)
    
//  setUser(res)
//   console.log(" 2")
//   }
 
  const submitSurvey: SubmitHandler<SurveyFormSubmitType> = async (
    data: SurveyFormSubmitType
  ) => {
    console.log(data);
  
    // Call fetchExistingUser to check if the user exists
    const userResponse = await GET(`/home?email=${data?.email}&survey_id=${params?.survey_id}`);
    console.log("User response",userResponse)
  // console.log(userResponse,"heloo response")
    if (userResponse?.[0] === 200) {
      // User exists, do something
      alert("User already completed the survey");
    } else if (userResponse?.[0] === 404) {
      // User doesn't exist, you can proceed to submit the data
      const [statusCode, res] = await POST(`/home`, {...data,
      survey_id:Number(params?.survey_id),
      survey_title:params?.survey_title
      });
      console.log("res>>>>>>>>>>.",res)
      if (statusCode === 200) {
        // Data submitted successfully
        alert('Success');
        // You can navigate to another page if needed
        // navigate(`/survey-page/?email=${data?.email}`);
        navigate(`/survey-page/${userResponse[1].survey_id}/?email=${data?.email}`);

      } else {
        console.log("ERROR: ", res);
      }
    }
  };
  


  const params = useParams();

console.log(params,"hellooo")

  return (
    <>
      <div className="main">
        <Navbar />
        <div className="hero">
          <div className="description-container">
            {/* <div className="img-container"> */}
            <BannerImg />
            {/* </div> */}
            <div className="text-container">
              <h1>Market research - all in one platform </h1>
              <p>
                "Rivazia helps you with all your market research needs. You can
                perform end- end market research, create a survey and acquire
                global-level survey samples for every industry out there."
              </p>
            </div>
          </div>
          <div className="form-descrption">
            <div className="title-container">
              <h2>Start Your Survey</h2>
            </div>
            <div className="form-container">
              <form onSubmit={handleSubmit(submitSurvey)}>
                <div className="form-inputs">
                  <label>First Name</label>
                  <input {...register("first_name")} type="text" />
                  {errors.first_name && (
                    <span className="Error-Message">{errors.first_name.message}</span>
                  )}
                </div>
                <div className="form-inputs">
                  <label>Last Name</label>
                  <input {...register("last_name")} type="text" />
                  {errors.last_name && <span className="Error-Message">{errors.last_name.message}</span>}
                </div>
                <div className="form-inputs">
                  <label>Phone Number</label>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ field: { onChange } }) => {
                      return (
                        <PhoneInput
                          defaultCountry="IN"
                          placeholder="Enter phone number"
                          type="text"
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                  {/* <PhoneInput
                    defaultCountry="IN"
                    placeholder="Enter phone number"
                    value={values?.phone}
                    type="text"
                    // onChange={(_) => onValueChange("phone")(_)}  
                    // value={values?.phone}
                  /> */}
                  {errors.phone && <span className="Error-Message">{errors.phone.message}</span>}
                </div>
                <div className="form-inputs">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange } }) => {
                      return (
                        <select
                          id="state"
                          name="state"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setState(e.target.value);
                            onChange(e.target.value);
                          }}
                          // onClick={console.log}
                        >
                          <option value="-">-</option>
                          {states
                            ? states.map((state) => (
                                <option
                                  key={state.id}
                                  value={`${state.name}-${state.iso2}`}
                                >
                                  {state.name}
                                </option>
                              ))
                            : ""}
                        </select>
                      );
                    }}
                  />
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <Controller
                    control={control}
                    name="city"
                    render={({ field: { onChange } }) => {
                      return (
                        <select
                          id="city"
                          name="city"
                          className="form-select"
                          aria-label="Default select example"
                          // onClick={console.log}
                          onChange={onChange}
                        >
                          <option value="-">-</option>
                          {cities
                            ? cities.map((city) => (
                              <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                              ))
                            : ""}
                        </select>
                      );
                    }}
                  />

                  {/* <StateCity
                    onCityChange={(_) => {
                      onValueChange("city")(_?.target.value);
                    }}
                    onStateChange={(_) =>
                      onValueChange("state")(_?.target.value)
                    }
                  /> */}
                </div>
                <div className="form-inputs">
                  <label>Email</label>
                  <input
                    {...register("email")}
                    // onChange={(_) => onValueChange("email")(_?.target?.value)}
                    // value={values?.email}
                  />
                  {errors.email && <span className="Error-Message">{errors.email.message}</span>}
                </div>
              </form>
              <div className="form-inputs">
                <button
                  className="sub-btn"
                  // disabled={disable}
                  onClick={handleSubmit(submitSurvey)}
                  type = "submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}



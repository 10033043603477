import React from 'react'
import styled from 'styled-components'

export default function index({ width, height }: any){
  return (
    <>
<SVG>
<svg width={width} height={height} viewBox="0 0 677 174" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1_3)">
<path d="M46.4 116.68C45.44 116.68 44.49 116.32 43.76 115.59C42.3 114.13 42.3 111.77 43.76 110.31L94.06 60.01L43.62 9.57001C42.16 8.11001 42.16 5.75001 43.62 4.29001C45.08 2.83001 47.44 2.83001 48.9 4.29001L101.98 57.37C103.44 58.83 103.44 61.19 101.98 62.65L49.04 115.59C48.31 116.32 47.36 116.68 46.4 116.68V116.68Z" fill="#2191FF" className="svg-elem-1"></path>
<path d="M46.4 119.88C44.55 119.88 42.81 119.16 41.5 117.85C38.8 115.15 38.8 110.75 41.5 108.05L89.54 60.01L41.36 11.83C38.66 9.13 38.66 4.73001 41.36 2.03C44.06 -0.669995 48.46 -0.669995 51.16 2.03L104.24 55.11C106.94 57.81 106.94 62.21 104.24 64.91L51.3 117.85C49.99 119.16 48.25 119.88 46.4 119.88ZM46.26 6.4C46.12 6.4 45.99 6.45 45.88 6.56C45.67 6.77 45.67 7.11 45.88 7.32L98.58 60.02L46.02 112.58C45.81 112.79 45.81 113.13 46.02 113.34C46.28 113.6 46.52 113.6 46.78 113.34L99.72 60.4C99.93 60.19 99.93 59.85 99.72 59.64L46.63 6.55C46.53 6.45 46.39 6.4 46.25 6.4H46.26Z" fill="#2191FF" className="svg-elem-2"></path>
<path d="M60.01 169.82C59.05 169.82 58.1 169.46 57.37 168.73L4.29001 115.65C2.83001 114.19 2.83001 111.83 4.29001 110.37L57.23 57.43C58.69 55.97 61.05 55.97 62.51 57.43C63.97 58.89 63.97 61.25 62.51 62.71L12.21 113.01L62.65 163.45C64.11 164.91 64.11 167.27 62.65 168.73C61.92 169.46 60.97 169.82 60.01 169.82Z" fill="#5EC273" className="svg-elem-3"></path>
<path d="M60.01 173.02C58.16 173.02 56.42 172.3 55.11 170.99L2.03 117.91C-0.669995 115.21 -0.669995 110.81 2.03 108.11L54.97 55.17C57.67 52.47 62.07 52.47 64.77 55.17C67.47 57.87 67.47 62.27 64.77 64.97L16.73 113.01L64.91 161.19C67.61 163.89 67.61 168.29 64.91 170.99C63.6 172.3 61.86 173.02 60.01 173.02ZM59.87 59.54C59.73 59.54 59.6 59.59 59.49 59.7L6.55 112.63C6.34 112.84 6.34 113.18 6.55 113.39L59.63 166.47C59.89 166.73 60.13 166.73 60.39 166.47C60.6 166.26 60.6 165.92 60.39 165.71L7.68 113.01L60.25 60.45C60.46 60.24 60.46 59.9 60.25 59.69C60.15 59.59 60.01 59.54 59.87 59.54Z" fill="#5EC273" className="svg-elem-4"></path>
<path d="M224.85 64.68C224.85 69.17 224.23 73.45 222.99 77.53C221.75 81.61 220.03 85.36 217.85 88.79C215.66 92.22 213 95.32 209.87 98.1C206.74 100.88 203.28 103.15 199.5 104.92L224.85 144.27H199.5L176.99 109.35H162.45L171.14 88.08H180.36C186.74 88.08 192.24 85.78 196.85 81.17C198.98 78.93 200.63 76.38 201.81 73.55C202.99 70.71 203.58 67.76 203.58 64.69C203.58 61.62 202.99 58.66 201.81 55.83C200.63 52.99 198.97 50.51 196.85 48.38C192.24 43.77 186.75 41.47 180.36 41.47H155.19V144.28H133.92V20.19H180.36C186.5 20.19 192.26 21.37 197.64 23.73C203.02 26.1 207.71 29.29 211.73 33.3C215.75 37.32 218.94 42.02 221.3 47.39C223.66 52.77 224.84 58.53 224.84 64.67L224.85 64.68Z" fill="black" className="svg-elem-5"></path>
<path d="M236.37 20.19H257.64V41.46H236.37V20.19V20.19ZM236.37 144.27V55.64H257.64V144.27H236.37V144.27Z" fill="black" className="svg-elem-6"></path>
<path d="M370.37 55.64L329.25 144.27H305.85L264.73 55.64H288.13L317.56 119.1L346.99 55.64H370.39H370.37Z" fill="black" className="svg-elem-7"></path>
<path d="M458.65 55.47V144.28H437.38V99.96C437.38 92.87 434.9 86.84 429.93 81.88C424.97 76.92 418.94 74.44 411.85 74.44C408.42 74.44 405.14 75.09 402.01 76.39C398.88 77.69 396.13 79.52 393.77 81.89C388.81 86.85 386.32 92.88 386.32 99.97C386.32 107.06 388.8 112.91 393.77 117.87C396.13 120.35 398.88 122.21 402.01 123.45C405.14 124.69 408.42 125.31 411.85 125.31C415.28 125.31 418.59 124.72 421.42 123.54L429.04 142.51C423.6 145.23 417.28 146.59 410.07 146.59C403.57 146.59 397.57 145.38 392.08 142.96C386.58 140.54 381.83 137.2 377.81 132.94C373.79 128.69 370.66 123.72 368.41 118.05C366.16 112.38 365.04 106.35 365.04 99.97C365.04 93.59 366.16 87.38 368.41 81.71C370.65 76.04 373.79 71.07 377.81 66.82C381.83 62.57 386.58 59.23 392.08 56.8C397.58 54.38 403.57 53.17 410.07 53.17C415.86 53.17 421 54.06 425.49 55.83C429.98 57.6 433.94 59.85 437.37 62.57V55.48H458.64L458.65 55.47Z" fill="black" className="svg-elem-8"></path>
<path d="M543.03 70.18L500.31 123H540.9V144.27H467.51V129.95L510.23 77.09H471.23V55.64H527.95V55.82H543.02V70.18H543.03Z" fill="black" className="svg-elem-9"></path>
<path d="M551.89 20.19H573.16V41.46H551.89V20.19ZM551.89 144.27V55.64H573.16V144.27H551.89V144.27Z" fill="black" className="svg-elem-10"></path>
<path d="M676.5 55.47V144.28H655.23V99.96C655.23 92.87 652.75 86.84 647.78 81.88C642.82 76.92 636.79 74.44 629.7 74.44C626.27 74.44 622.99 75.09 619.86 76.39C616.73 77.69 613.98 79.52 611.62 81.89C606.66 86.85 604.17 92.88 604.17 99.97C604.17 107.06 606.65 112.91 611.62 117.87C613.98 120.35 616.73 122.21 619.86 123.45C622.99 124.69 626.27 125.31 629.7 125.31C633.13 125.31 636.44 124.72 639.27 123.54L646.89 142.51C641.45 145.23 635.13 146.59 627.92 146.59C621.42 146.59 615.42 145.38 609.93 142.96C604.43 140.54 599.68 137.2 595.66 132.94C591.64 128.69 588.51 123.72 586.26 118.05C584.01 112.38 582.89 106.35 582.89 99.97C582.89 93.59 584.01 87.38 586.26 81.71C588.5 76.04 591.64 71.07 595.66 66.82C599.68 62.57 604.43 59.23 609.93 56.8C615.43 54.38 621.42 53.17 627.92 53.17C633.71 53.17 638.85 54.06 643.34 55.83C647.83 57.6 651.79 59.85 655.22 62.57V55.48H676.49L676.5 55.47Z" fill="black" className="svg-elem-11"></path>
</g>
<defs>
<clipPath id="clip0_1_3">
<rect width="676.5" height="173.02" fill="white" className="svg-elem-12"></rect>
</clipPath>
</defs>
</svg>

</SVG>

    </>
  )
}

const SVG =styled.div`

@-webkit-keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(33, 145, 255);
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(33, 145, 255);
  }
}

.svg-elem-1 {
  -webkit-animation: animate-svg-fill-1 0.7s ease-in 0.8s both;
          animation: animate-svg-fill-1 0.7s ease-in 0.8s both;
}

@-webkit-keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(33, 145, 255);
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(33, 145, 255);
  }
}

.svg-elem-2 {
  -webkit-animation: animate-svg-fill-2 0.7s ease-in 0.9s both;
          animation: animate-svg-fill-2 0.7s ease-in 0.9s both;
}

@-webkit-keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(94, 194, 115);
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(94, 194, 115);
  }
}

.svg-elem-3 {
  -webkit-animation: animate-svg-fill-3 0.7s ease-in 1s both;
          animation: animate-svg-fill-3 0.7s ease-in 1s both;
}

@-webkit-keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(94, 194, 115);
  }
}

@keyframes animate-svg-fill-4 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(94, 194, 115);
  }
}

.svg-elem-4 {
  -webkit-animation: animate-svg-fill-4 0.7s ease-in 1.1s both;
          animation: animate-svg-fill-4 0.7s ease-in 1.1s both;
}

@-webkit-keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-5 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-5 {
  -webkit-animation: animate-svg-fill-5 0.7s ease-in 1.2000000000000002s both;
          animation: animate-svg-fill-5 0.7s ease-in 1.2000000000000002s both;
}

@-webkit-keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-6 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-6 {
  -webkit-animation: animate-svg-fill-6 0.7s ease-in 1.3s both;
          animation: animate-svg-fill-6 0.7s ease-in 1.3s both;
}

@-webkit-keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-7 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-7 {
  -webkit-animation: animate-svg-fill-7 0.7s ease-in 1.4000000000000001s both;
          animation: animate-svg-fill-7 0.7s ease-in 1.4000000000000001s both;
}

@-webkit-keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-8 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-8 {
  -webkit-animation: animate-svg-fill-8 0.7s ease-in 1.5s both;
          animation: animate-svg-fill-8 0.7s ease-in 1.5s both;
}

@-webkit-keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-9 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-9 {
  -webkit-animation: animate-svg-fill-9 0.7s ease-in 1.6s both;
          animation: animate-svg-fill-9 0.7s ease-in 1.6s both;
}

@-webkit-keyframes animate-svg-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-10 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-10 {
  -webkit-animation: animate-svg-fill-10 0.7s ease-in 1.7000000000000002s both;
          animation: animate-svg-fill-10 0.7s ease-in 1.7000000000000002s both;
}

@-webkit-keyframes animate-svg-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

@keyframes animate-svg-fill-11 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 0, 0);
  }
}

.svg-elem-11 {
  -webkit-animation: animate-svg-fill-11 0.7s ease-in 1.8s both;
          animation: animate-svg-fill-11 0.7s ease-in 1.8s both;
}

@-webkit-keyframes animate-svg-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

@keyframes animate-svg-fill-12 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(255, 255, 255);
  }
}

.svg-elem-12 {
  -webkit-animation: animate-svg-fill-12 0.7s ease-in 1.9000000000000001s both;
          animation: animate-svg-fill-12 0.7s ease-in 1.9000000000000001s both;
}

`

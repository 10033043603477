import React, { useContext, useEffect } from "react";
import BannerImg from "../../assets/bannerImg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { ResponseContext } from "../../context/ResponseContext";
import { useLocation, useParams,useNavigate } from "react-router-dom";
import { log } from "console";
export default function Main() {
  const paramsValue = useParams();
console.log("paramsValue>>>>>>>>>>>>>>>..", paramsValue);
const {surveyData, setSurveyData } = useContext(ResponseContext);
function setSurveyDataFromParams(){
  setSurveyData({
    survey_id:Number(paramsValue.survey_id),
    survey_title:paramsValue.survey_title
  })
}
console.log("surveyData",surveyData)
useEffect(() => {
  setSurveyDataFromParams()
  }, [])
  const navigate = useNavigate()
  // if (paramsValue) {
  //   setSurveyData({
  //     survey_id: Number(paramsValue.survey_id),
  //     survey_title: paramsValue.survey_title,
  //   });
  // }
  // console.log("-------",setSurveyData)
  // useEffect(() => {
  //   // Parse the survey_id from the URL parameter
  //   const searchParams = new URLSearchParams(location.search);
  //   const surveyIdParam = searchParams.get("check");
  //   const surveyTitleParam = searchParams.get("survey_title");

  //   if (surveyIdParam && surveyTitleParam) {
  //     setSurveyData({
  //       survey_id: Number(surveyIdParam),
  //       survey_title: surveyTitleParam
  //     });
  //   }
  // }, [location.search, setSurveyData]);
  // const { survey_id, survey_title } = surveyData

  return (
    <>
      <div className="main">
        <Navbar />
        <div className="hero">
          <div className="description-container">
            <BannerImg />
            <div className="text-container">
              {/* <h1>Market research - all in one platform </h1> */}
              <h1>Rivazia - Coming Soon </h1>
              <p>
                "Rivazia helps you with all your market research needs. You can
                perform end- end market research, create a survey and acquire
                global-level survey samples for every industry out there."
              </p>
            </div>
          </div>
          <div className="form-descrption">
            {/* <h2> Attend Diabetes Survey and Earn Rewards</h2> */}
            <h3> Good Day, participant</h3>
            <p>
              {" "}
              We appreciate your decision to take part in this survey.
              {/* The goal of this survey is to get insightful patient feedback to enhance the diabetic healthcare system in our nation.
              We are gathering information from individuals with type 2 diabetes or individuals whose family members have the disease. */}
              <h4 style={{ color: "#407bff" }}>
                Once the information is determined to be relevant to our needs,
                we'll pay you an honorarium for your participation.
              </h4>
            </p>
            <p>
              Please don't falsify any values because we need a quality dataset.
              Please don't skip any questions; they are all significant.
            </p>
           
              <button className="sub-btn" onClick={()=>navigate(`Survey-Home/${paramsValue.survey_id}`)}>Click Here</button>
              {/* {survey_id} */}
              {/* {surveyId} */}
       
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

import React from "react";
import RootProvider from "./context/RootContext";
import Router from "./router";
import { ResponseProvider } from "./context/ResponseContext";

import Home from "../src/pages/Main"

function App() {
  return (
    <>

      <ResponseProvider>
        {/* <RootProvider> */}
        <Router />
        {/* </RootProvider> */}
        {/* <Home/> */}
      </ResponseProvider>
    </>
  );
}

export default App;

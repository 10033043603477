import  { createContext,  useState } from "react";

export type ValueContextType={
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  city: string;
  state: string;
}

export type ResponseContextType={
  values:ValueContextType|undefined;
  setValues: React.Dispatch<React.SetStateAction<ValueContextType>>;
  surveyData: SurveyDataType|undefined;
  setSurveyData: React.Dispatch<React.SetStateAction<SurveyDataType>>
}

export type SurveyDataType={
  survey_id: number;
  survey_title: string;
}

export const ResponseContext = createContext<ResponseContextType>({} as ResponseContextType);

export const ResponseProvider = ({ children }) => {
  const [values, setValues] = useState<ValueContextType>();
  const [surveyData, setSurveyData] = useState<SurveyDataType>();
  return (
    <ResponseContext.Provider
      value={{
        values,
        setValues,
       surveyData,
       setSurveyData
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
};

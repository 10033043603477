// export let controller = new AbortController();

import { apiEndPoint } from "../Utils/Functions/Constants";

export const POST = async (path?: string, data?: any, token?: string) => {
  const res = await fetch(`${apiEndPoint}${path}`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = await res.json();
      console.log(json);
      return [res?.status, json];
    })
    .then((res) => {
      console.log("s", res);
      return res;
    })
    .catch((error) => {
      console.log(`error in post api ${apiEndPoint}${path} >`, error);
      return [500, error];
    });
  return res;
};

// // ------------------------------POST
interface patchProps {
  apiUri: string;
  data: Object;
  token?: string;
}
export const PATCH = async ({ apiUri, data, token }: patchProps) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in patch ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

export const DELETE = async (apiUri?: string, token?: string) => {
  const res = await fetch(`${apiEndPoint}${apiUri}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in delete ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

// export const PUT = async (apiUri?:string, data?:any, token?:string) => {
//   const res = await fetch(`${apiEndPoint}${apiUri}`, {
//     method: "PUT",
//     mode: "no-cors",
//     body: JSON.stringify(data),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//     .then((res) => {
//       console.log(res);
//       // const json = await res.json()
//       // return [res?.status, json];
//     })
//     .catch((error) => {
//       console.log(`error in put ${apiUri} >`, error);
//     });
//   return res;
// };

export const GET = async (
  apiUri?: string,
  signal?: AbortSignal,
  token?: string
) => {
  const endpoint = `${apiEndPoint}${apiUri}`;
  console.log("endpoint > ", endpoint);
  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors",
    signal: signal,
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in api ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

import React from 'react'
import Facebook from "../../assets/facebook";
import Instagram from "../../assets/instagram";
import Twitter from "../../assets/twitter";
import Linkdln from '../../assets/linkdln';
import "./style.css";

export default function index() {
  return (
    <div className='overall-footer-content'>
      <div className="footer">
        <div style={{ display: "flex", gap: "10px ",justifyContent:"center" }} className="social-links">
          <div onClick={() => window.open('https://www.facebook.com/profile.php?id=100087495820024')} ><Facebook /></div>
          <div onClick={() => window.open("https://www.instagram.com/the_rivazia/")}>   <Instagram /></div>
          <div onClick={() => window.open("https://twitter.com/@RivaziaInfo")}>   <Twitter /></div>
          <div onClick={() => window.open("https://www.linkedin.com/company/rivazia/")}>   <Linkdln /></div>

          <a href=""></a>
      <div className='footer-contact' >
        <div>Toll Free-9150080333 </div>
        <div>Email-info@rivazia.com</div>
        <div>copyrights@rivazia 2022 - 2023 | Designed by XBI4</div>

      </div>
        </div>
      </div>
    </div>
  )
}
